import React from "react";
import PageTemplate from "../components/page-template";
import Hero from "../components/hero";
// import AboutUs from "../components/about-us";

const WhatWeDo = () => {
  return (
    <PageTemplate title="About Us | Innovate Web Solutions">
        <Hero size="small" color="primary" content={
            <div>
                <h1 className="title color-primary">What we Do</h1>
            </div>
        }/>

        <section>
        test
        </section>
    </PageTemplate>
  );
};

export default WhatWeDo;